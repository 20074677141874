import { TemplateElement, html, defineElement, choose } from '@webtides/element-js';
import '../author-teaser/author-teaser.js';

/**
 * @property {string} layoutVariant
 * @property {array} authors
 */
export default class AuthorTeasers extends TemplateElement {
    properties() {
        return {
            layoutVariant: 'grid', // list || grid
            authors: [],
        };
    }

    template() {
        return html` ${this.renderList(this.layoutVariant, this.authors)} `;
    }

    renderList(listType, authors) {
        return choose(listType, {
            list: html` <div class="flex flex-col gap-16 md:gap-48">${this.renderTeasers(authors)}</div>`,
            grid: html` <div class="flex sm:grid flex-col sm:grid-cols-2 lg:grid-cols-3 gap-16">
                ${this.renderTeasers(authors)}
            </div>`,
        });
    }

    renderTeasers(authors) {
        return html`
            ${authors.map(
                (author) =>
                    html`<mb-author-teaser
                        name="${author.firstName} ${author.lastName}"
                        description="${author.description}"
                        image-link="${author.image ? author.image.link : ''}"
                        image-alt-text="${author.image ? author.image.altText : ''}"
                        page-link="${author.link}"
                        linked-in-page-link="${author.linkedInUrl}"
                    >
                    </mb-author-teaser>`,
            )}
        `;
    }
}

defineElement('mb-author-teasers', AuthorTeasers);
